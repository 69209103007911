<template>
  <main
    class="layout__body no-padding"
    :class="{ 'prevent-scrolling': isSearchOpened }"
  >
    <header class="market-fixed-header" ref="header">
      <div class="market-header-card">
        <div class="market-header-title">
          <span class="market-header-main">Магазин</span>
          <div class="cart-limit-container">
            <span class="cart-limit-amount" style="padding-left: 0 !important;">
              {{ limit ? `${limit.toLocaleString("ru")} E` : "Нет лимита" }}
            </span>
          </div>
        </div>
        <MerchSearch
          @disableScroll="disableScroll"
          @enableScroll="enableScroll"
        />
      </div>
    </header>
    <div class="market-container" :style="{ marginTop: headerHeight + 8 + 'px' }">
      <v-skeleton-loader
        v-if="isLoading"
        type="list-item@10"
        class="skeleton-category"
      ></v-skeleton-loader>
      <!-- <NoProducts v-else-if="categories.length === 0"/> -->
      <NoProducts v-else-if="categories.length === 0"/>
      <div v-else>
        <router-link
          v-for="(tab, index) in categories"
          :key="tab.id"
          :to="{ name: 'Merch List of products', params: { categoryId: tab.id } }"
        >
          <div class="market-category-item">
            <div class="market-category-left">
              <img
                v-if="!nonExistentIcons.includes(tab.name_code)"
                :src="getIcon(tab.name_code)"
                @error="nonExistentIcons.push(tab.name_code)"
                class="market-category-img-static"
              />
<!--              <img-->
<!--                v-else-->
<!--                :src="tab.image"-->
<!--              />-->
              <span class="market-category-text">{{ tab.name }}</span>
            </div>
            <div class="market-category-right">
              <span class="market-category-products-count">
                {{ tab.products_count }}
              </span>
              <img src="@/assets/marketplace/chevron_right.svg" alt="right" />
            </div>
          </div>
          <hr v-if="index !== categories.length - 1" class="market-divider" />
        </router-link>
      </div>
    </div>
  </main>
</template>

<script>
import MerchSearch from "@/components/merch/merch_search.vue";
import { mapState, mapActions } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import NoProducts from "@/components/merch/NoProducts.vue"

export default {
  components: {
    NoProducts,
    MerchSearch,
  },
  data: () => ({
    headerHeight: undefined,
    isSearchOpened: false,
    nonExistentIcons: [],
    isLoading: true
  }),
  computed: {
    ...mapState("merch", ["limit", "categories"]),
  },
  async created() {
    await this.getUserInfo();
    await this.getCategories();
    this.isLoading = false
  },
  mounted() {
    backNavigateQueue.set(this.exitFromMarket);
    this.$store.dispatch('layout/setHeaderTitle', 'Marketplace');
    this.getHeaderHeight();
  },
  methods: {
    ...mapActions("merch", ["getUserInfo", "getCategories"]),
    getHeaderHeight() {
      this.headerHeight = this.$refs.header.clientHeight;
      this.$forceUpdate();
    },
    disableScroll() {
      this.isSearchOpened = true;
    },
    enableScroll() {
      this.isSearchOpened = false;
    },
    exitFromMarket() {
      this.$router.replace({ name: 'Marketplace' });
    },
    getIcon(name) {
      try {
        return require(`@/assets/merch/categories/${name}.svg`)
      } catch (e) {
        this.nonExistentIcons = [...this.nonExistentIcons, name]
        throw new Error(e)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.market-header-card, .market-container {
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
