<template>
  <main
    class="layout__body no-padding"
    :class="{ 'prevent-scrolling': isSearchOpened }"
  >
    <header class="market-fixed-header" ref="header">
      <div class="market-header-card">
        <div class="market-header-title">
          <div class="market-header-with-back-btn">
            <v-skeleton-loader
              v-if="!parentCategory"
              type="text"
              class="skeleton-header"
              width="150"
            ></v-skeleton-loader>
            <span v-else class="market-header-category">
              {{ parentCategory.name }}
            </span>
          </div>
          <div class="cart-limit-container">
            <span class="cart-limit-amount" style="padding-left: 0 !important;">
              {{ limit ? `${limit.toLocaleString("ru")} E` : "Нет лимита" }}
            </span>
          </div>
        </div>
        <MerchSearch
          ref="merchSearch"
          :isListOfProducts="isListOfProducts"
          :categoryId="categoryId"
          @activateSkeletonLoader="activateSkeletonLoader"
          @deactivateSkeletonLoader="deactivateSkeletonLoader"
        />
      </div>
    </header>
    <div class="market-container" :style="{ marginTop: headerHeight + 8 + 'px' }">
      <div
        v-if="isProductsLoading"
        class="skeleton-list-of-products-container"
      >
        <div v-for="n in 4" :key="n" class="skeleton-item">
          <v-skeleton-loader
            type="image, list-item-two-line"
            class="skeleton-list-of-products"
          ></v-skeleton-loader>
        </div>
      </div>
      <div v-else class="product-list">
        <div class="product-list-amount">
          <span>Найдено товаров: </span>
          <strong>{{ productsCount }}</strong>
        </div>
        <div class="product-list-card-container">
          <div
            class="product-list-card"
            v-for="product in productsList"
            :key="product.id"
          >
            <router-link
              :to="{ name: 'MerchProductById', params: { productId: product.id } }"
            >
              <div class="product-list-image">
                <img
                  v-if="product.image"
                  :src="api_url + product.image"
                  alt="product_image"
                  style="max-width: 100%; max-height: 100%"
                />
                <img
                  v-else
                  src="@/assets/marketplace/no_image.svg"
                  alt="no_image"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
              <div
                v-if="product.availability.num_available > 0"
                class="product-list-availability"
              >
                В наличии: {{ product.availability.num_available }}
              </div>
              <div v-else class="product-list-availability not-available">
                Под заказ
              </div>
              <h5 class="product-list-title">{{ product.title }}</h5>
              <v-rating
                :value="product.rating"
                size="14"
                color="#EF7F1A"
                half-increments
                readonly
                dense
              ></v-rating>
              <div class="product-list-price">
                {{ parseFloat(product.price.excl_tax || 0).toLocaleString("ru") }} E
              </div>
            </router-link>
            <button
              type="button"
              v-ripple="{ center: true }"
              class="add-to-cart-from-list-btn"
              @click="addToCart({ id: product.id })"
              v-if="in_cart_qty_by_id(product.id) === 0"
            >
              В корзину
            </button>
            <div
              v-else-if="in_cart_qty_by_id(product.id) > 0"
              class="in-cart-actions"
            >
              <button
                type="button"
                v-ripple="{ center: true }"
                class="remove-from-cart-btn"
                @click="removeFromCart(product.id)"
                v-if="in_cart_qty_by_id(product.id) === 1"
              >
                <img src="@/assets/marketplace/trash_can.svg" alt="trash" />
              </button>
              <button
                type="button"
                v-ripple="{ center: true }"
                class="remove-from-cart-btn"
                @click="removeFromCart(product.id)"
                v-else-if="in_cart_qty_by_id(product.id) > 1"
              >
                <span>-</span>
              </button>
              <span class="in-cart-amount">
                {{ in_cart_qty_by_id(product.id) }} шт.
              </span>
              <button
                type="button"
                v-ripple="{ center: true }"
                class="add-more-to-cart-btn"
                @click="addToCart({ id: product.id })"
              >
                <span>+</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import MerchSearch from "@/components/merch/merch_search.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";

export default {
  components: {
    MerchSearch,
  },
  props: {
    categoryId: [Number, String],
  },
  data: () => ({
    headerHeight: undefined,
    isProductsLoading: true,
    isListOfProducts: true,
    isSearchOpened: false,
  }),
  computed: {
    ...mapState("merch", [
      "api_url",
      "limit",
      "cart",
      "filteredProducts",
    ]),
    ...mapGetters("merch", ["all_categories", "in_cart_qty_by_id"]),
    parentCategory() {
      return this.all_categories.find((category) => {
        return category.id == this.categoryId;
      });
    },
    productsList() {
      if (this.filteredProducts) return this.filteredProducts;
      else return this.productsByCategory;
    },
    productsCount() {
      return this.filteredProducts ? this.filteredProducts.length : 0
      // if (this.filtersPaginationInfo) return this.filtersPaginationInfo.count;
      // else if (this.productsPaginationInfo) return this.productsPaginationInfo?.count;
      // else return 0;
    },
  },
  async created() {
    await this.getProductsByFilter(this.categoryId);
    amplitudeMarketplace({
      event_type: "Мерчи - Переход в категорию",
      extra_info: this.parentCategory.name,
      project_name: "Merch"
    });
    this.deactivateSkeletonLoader();
  },
  mounted() {
    backNavigateQueue.set(this.backToCategories);
    this.getHeaderHeight();
    console.log('mounted');
  },
  methods: {
    ...mapActions("merch", [
      "getProductsByFilter",
      "addToCart",
      "removeFromCart",
      "clearQueryParams",
      "clearProductsByFilter",
    ]),
    activateSkeletonLoader() {
      this.isProductsLoading = true;
    },
    deactivateSkeletonLoader() {
      this.isProductsLoading = false;
    },
    getHeaderHeight() {
      this.headerHeight = this.$refs.header?.clientHeight;
      this.$forceUpdate();
      console.log('got header', this.headerHeight);
    },
    backToCategories() {
      this.$router.go(-1);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "MerchProductById") {
      this.clearQueryParams();
      this.clearProductsByFilter();
      next();
    } else next();
  },
};
</script>

<style lang="scss" scoped>
.market-header-card, .market-container {
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
