<template>
  <div class="cart-container" :class="{ 'grey-bg': isOrdersAvailable }">
    <div :class="{ 'market-header-container': isOrdersAvailable }">
      <v-card flat class="market-header-card">
        <v-card-text class="d-flex align-items-center justify-center">
          <div class="cart-header-title">
            <span>История заказов</span>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="isOrdersLoading" style="background-color: #FFFFFF">
      <div v-for="(n, index) in 5" :key="n">
        <div class="skeleton-orders-container">
          <v-skeleton-loader
            type="text@2"
            class="skeleton-orders-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="avatar@3"
            class="skeleton-orders-images"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="heading, text"
            class="skeleton-orders-footer"
          ></v-skeleton-loader>
        </div>
        <hr v-if="index !== 4" class="market-divider" />
      </div>
    </div>
    <div v-else>
      <v-row no-gutters v-if="!isOrdersAvailable">
        <v-col cols="12" class="empty-cart">
          <v-icon size="56" color="#EF7F1A" class="empty-cart-icon">
            mdi-clock-outline
          </v-icon>
          <h5 class="empty-cart-title">Заказов ещё нет</h5>
          <p class="empty-cart-text">
            Воспользуйтесь каталогом, чтобы купить товар
          </p>
          <v-btn
            width="100%"
            height="56"
            color="#EF7F1A"
            class="go-to-market-btn"
            :to="{ name: 'Merch' }"
          >
            Перейти в каталог
          </v-btn>
        </v-col>
      </v-row>
<!--      <router-link-->
<!--        v-if="approveOrders && approveOrders.length > 0"-->
<!--        :to="{ name: 'MerchApproveOrders' }"-->
<!--        class="grey-bg"-->
<!--      >-->
<!--        <div class="to-approve-orders">-->
<!--          <span>-->
<!--            Заказов на согласовании:-->
<!--            <strong>{{ approveOrdersPaginationInfo.count }}</strong>-->
<!--          </span>-->
<!--          <img src="@/assets/marketplace/chevron_right.svg" alt="right" />-->
<!--        </div>-->
<!--      </router-link>-->
<!--      <router-link-->
<!--        v-if="acceptOrders && acceptOrders.length > 0"-->
<!--        :to="{ name: 'MerchAcceptOrders' }"-->
<!--        class="grey-bg"-->
<!--      >-->
<!--        <div class="to-approve-orders">-->
<!--          <span>-->
<!--            Заказов на приёмке:-->
<!--            <strong>{{ acceptOrdersPaginationInfo.count }}</strong>-->
<!--          </span>-->
<!--          <img src="@/assets/marketplace/chevron_right.svg" alt="right" />-->
<!--        </div>-->
<!--      </router-link>-->
      <div class="market-container">
        <router-link
          v-for="(order, index) in orders"
          :key="order.pk"
          :to="{ name: 'MerchOrderById', params: { id: order.pk } }"
        >
          <div class="order-card">
            <div style="margin-bottom: 16px">
              <div class="order-title">
                <span
                  class="order-status orange-status"
                  :class="{
                    'red-status': statuses.order__cancelled && statuses.order__cancelled.includes(
                      order.status
                    ),
                    'green-status': statuses.order__completed && statuses.order__completed.includes(
                      order.status
                    ),
                  }"
                >
                  {{ order.status }}
                </span>
                <span class="order-number"># {{ order.number }}</span>
              </div>
              <template v-if="order.notes && order.notes.length > 0">
                <div
                  v-for="(note, index) in order.notes"
                  :key="index"
                  class="order-comments"
                >
                  Комментарий: {{ note }}
                </div>
              </template>
            </div>
            <div class="order-photos">
              <div
                v-for="line in order.lines"
                :key="line.id"
                class="order-photo-container"
              >
                <img
                  v-if="line.product.image"
                  :src="api_url + line.product.image"
                  alt="product"
                  style="max-width: 100%; max-height: 100%"
                />
                <img
                  v-else
                  src="@/assets/marketplace/no_image.svg"
                  alt="no_image"
                  style="max-width: 100%; max-height: 100%"
                />
              </div>
            </div>
            <div class="order-subtitle">
              <span class="order-price">
                {{ parseFloat(order.total_excl_tax).toLocaleString("ru") }} E
              </span>
              <span class="order-product-count">
                Количество товаров: {{ order.lines.length }}
              </span>
            </div>
          </div>
          <hr v-if="index !== orders.length - 1" class="market-divider" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";

export default {
  props: {
    id: [Number, String],
  },
  data: () => ({
    isOrdersLoading: true,
  }),
  computed: {
    ...mapState("merch", [
      "api_url",
      "orders",
      "statuses",
      // "approveOrdersPaginationInfo",
      // "approveOrders",
      // "acceptOrdersPaginationInfo",
      // "acceptOrders",
    ]),
    // ...mapGetters("merch", ["order__can_approve", "order__can_accept"]),
    isOrdersAvailable() {
      return this.orders && this.orders.length > 0
    },
  },
  async created() {
    this.clearUserOrders();
    // this.clearApproveOrders();
    // this.clearAcceptOrders();
    await this.getUserInfo();
    await this.getUserOrders();
    // if (this.order__can_approve) await this.getApproveOrders();
    // if (this.order__can_accept) await this.getAcceptOrders();
    if (this.id) {
      this.$router.push({ name: "MerchOrderById", params: { id: this.id } });
    } else {
      amplitudeMarketplace({
        event_type: "Мерчи - Переход во вкладку Заказы",
        extra_info: null,
        project_name: "Merch"
      });
    }
    this.isOrdersLoading = false;
  },
  mounted() {
    backNavigateQueue.set(this.backNavigate);
  },
  methods: {
    ...mapActions("merch", [
      "getUserInfo",
      "getUserOrders",
      "clearUserOrders",
      // "getApproveOrders",
      // "clearApproveOrders",
      // "getAcceptOrders",
      // "clearAcceptOrders",
    ]),
    backNavigate() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.to-approve-orders {
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  background: #ffffff;
}
.small-margin {
  margin-bottom: 0;
  border-radius: 16px 16px 0 0;
}
</style>
