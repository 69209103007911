<template>
  <div
    data-app
    class="cart-container"
    :class="{ 'grey-bg': cart.lines.length > 0 }"
  >
    <div :class="{ 'market-header-container': cart.lines.length > 0 }">
      <v-card flat class="market-header-card">
        <v-card-text
          class="d-flex align-items-center justify-center"
          :class="{ 'justify-between': cart.lines.length > 0 }"
        >
          <div class="cart-header-title">
            <span>Корзина</span>
          </div>
          <div class="cart-limit-container" v-if="cart.lines.length > 0">
            <span class="cart-limit-amount" style="padding-left: 0 !important;">
              {{ limit ? `${limit.toLocaleString("ru")} E` : "Нет лимита" }}
            </span>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="!isCartLoaded">
      <div v-for="(n, index) in 5" :key="n">
        <div class="skeleton-orders-container">
          <v-skeleton-loader
            type="text@2"
            class="skeleton-orders-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="avatar@5"
            class="skeleton-orders-images"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="heading, text"
            class="skeleton-orders-footer"
          ></v-skeleton-loader>
        </div>
        <hr v-if="index !== 2" class="market-divider" />
      </div>
    </div>
    <div v-else>
      <v-row no-gutters v-if="cart.lines.length === 0">
        <v-col cols="12" class="empty-cart">
          <v-icon size="56" color="#EF7F1A" class="empty-cart-icon">
            mdi-cart
          </v-icon>
          <h5 class="empty-cart-title">В корзине пока пусто</h5>
          <p class="empty-cart-text">Перейдите в каталог, чтобы добавить товар</p>
          <v-btn
            width="100%"
            height="56"
            color="#EF7F1A"
            class="go-to-market-btn"
            :to="{ name: 'Merch' }"
          >
            Перейти в каталог
          </v-btn>
        </v-col>
      </v-row>
      <template v-else-if="cart.lines.length > 0">
        <v-card flat class="cart-info-card cart-price">
          <span class="cart-info-text">Сумма покупки</span>
          <span class="cart-info-text">
            {{ parsedTotalCartPrice.toLocaleString("ru") }} E
          </span>
        </v-card>
        <v-card flat class="cart-info-card cart-price">
          <span class="cart-info-text">Остаток ERG коинов</span>
          <span
            class="cart-info-text"
            :class="{ 'negative-limit': limitAfterOrder < 0 }"
          >
            {{ limitAfterOrder.toLocaleString("ru") }}
            {{ limit ? ' E' : '' }}
          </span>
        </v-card>
        <div style="margin-bottom: calc(96px + 64px) !important">
          <v-card
            flat
            class="cart-info-card"
            v-for="line in cart.lines"
            :key="line.id"
          >
            <v-img
              v-if="line.product.image"
              :src="api_url + line.product.image"
              height="90"
              max-width="90"
              contain
            ></v-img>
            <v-img
              v-else
              src="@/assets/marketplace/no_image.svg"
              height="90"
              max-width="90"
              contain
            ></v-img>
            <div class="cart-item-info">
              <h5 class="cart-item-price">
                {{ parseFloat(line.price_excl_tax).toLocaleString("ru") }} E
              </h5>
              <p class="cart-item-title">{{ line.product.title }}</p>
              <div class="cart-item-actions">
                <div class="in-cart-actions">
                  <button
                    type="button"
                    v-ripple="{ center: true }"
                    class="remove-from-cart-btn"
                    @click="checkForLastPiece(line)"
                  >
                    <span>-</span>
                  </button>
                  <span class="in-cart-amount"> {{ line.quantity }} шт. </span>
                  <button
                    type="button"
                    v-ripple="{ center: true }"
                    class="add-more-to-cart-btn"
                    @click="addToCartClicked(line.product)"
                  >
                    <span>+</span>
                  </button>
                </div>
                <v-btn
                  text
                  class="cart-item-remove"
                  @click="confirmDeletion(line)"
                >
                  удалить
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
        <v-card class="cart-confirm-order">
          <v-card-actions style="padding: 16px">
            <v-btn
              block
              color="#EF7F1A"
              class="cart-confirm-order-btn"
              :loading="is_loading"
              @click="confirmCheckout"
            >
              Оформить заказ
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </div>
    <v-dialog
      v-model="selectDialog"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card class="grey-bg">
        <div class="cart-select-header">
          <v-btn icon @click.stop="closeDialog" style="position: absolute">
            <v-icon size="36">mdi-chevron-left</v-icon>
          </v-btn>
          <span class="cart-select-title">
            {{ isAddressSelect ? "Выбор адреса" : "Выбор МВЗ" }}
          </span>
        </div>
      </v-card>
    </v-dialog>
    <AlertDialog
      :alertInfo="alertInfo"
      :alertDialog="alertDialog"
      @emitCloseAlert="closeAlert"
      @emitConfirmAlert="deleteProduct"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AlertDialog from "@/components/marketplace/alert_dialog.vue";
import { backNavigateQueue } from "@/helpers/app.js";
import { amplitudeMarketplace } from "@/helpers/amplitudeMarket";

export default {
  data: () => ({
    is_loading: false,
    isCartLoaded: false,
    selectDialog: false,
    isAddressSelect: undefined,
    selectAddress: false,
    selectMVZ: false,
    selectedAddress: "",
    selectedMVZ: "",
    deletingLineId: undefined,
    alertInfo: {},
    alertDialog: false,
  }),
  components: {
    AlertDialog,
  },
  computed: {
    ...mapState("merch", [
      "marketUser",
      "api_url",
      "limit",
      "addressList",
      "mvzList",
      "cart",
      "orders",
    ]),
    ...mapGetters("merch", ["cart_price_total"]),
    parsedTotalCartPrice() {
      return parseFloat(this.cart_price_total);
    },
    limitAfterOrder() {
      if (this.limit) return this.limit - this.parsedTotalCartPrice;
      else return 'Нет лимита';
    },
  },
  async created() {
    await this.getUserInfo();
    await this.getUserCart();
    this.isCartLoaded = true;
    amplitudeMarketplace({
      event_type: "Мерчи - Переход во вкладку Корзина",
      extra_info: null,
      project_name: "Merch"
    });
  },
  mounted() {
    backNavigateQueue.set(this.backNavigate);
  },
  methods: {
    ...mapActions("merch", [
      "getUserInfo",
      "getUserCart",
      "addToCart",
      "removeFromCart",
      "deleteProductFromCart",
      "checkoutOrder",
      "clearProduct",
    ]),
    backNavigate() {
      this.$router.go(-1);
    },
    addToCartClicked(product) {
      this.addToCart({ id: product.id });
      amplitudeMarketplace({
        event_type: "Мерчи - Добавление товара в Корзину",
        extra_info: product.title,
        project_name: "Merch"
      });
    },
    openAddressDialog() {
      this.selectDialog = true;
      this.isAddressSelect = true;
      this.selectAddress = true;
    },
    openMVZDialog() {
      this.selectDialog = true;
      this.isAddressSelect = false;
      this.selectMVZ = true;
    },
    closeDialog() {
      this.selectDialog = false;
      this.selectAddress = false;
      this.selectMVZ = false;
    },
    onAddressSelect(item) {
      this.selectedAddress = item;
      this.closeDialog();
      this.addressNotSelected = false;
    },
    onMVZSelect(item) {
      this.selectedMVZ = item;
      this.closeDialog();
    },
    checkForLastPiece(line) {
      if (line.quantity === 1) {
        this.confirmDeletion(line);
      } else {
        this.removeFromCart(line.product.id);
      }
    },
    confirmDeletion(payload) {
      this.deletingLine = payload;
      this.alertInfo = {
        alert_title: "Удаление из корзины",
        alert_message: payload.product.title,
        cancel_btn_text: "Отмена",
        confirm_btn_text: "Удалить",
      };
      this.alertDialog = true;
    },
    closeAlert() {
      this.alertDialog = false;
      this.alertInfo = {};
    },
    deleteProduct() {
      this.deleteProductFromCart({ lineId: this.deletingLine.id });
      this.deletingLine = undefined;
      this.closeAlert();
    },
    async confirmCheckout() {
      this.is_loading = true
      try {
        let res = await this.checkoutOrder({});
        amplitudeMarketplace({
          event_type: "Мерчи - Заказ создан",
          extra_info: null,
          project_name: "Merch"
        });
        this.$router.push({ name: "MerchOrders", params: { id: res.pk } });
        await this.getUserCart();
      } catch (err) {
        let errorMessage = err.response.data.message;
        if (errorMessage) {
          const title = errorMessage === 'Произошла ошибка, свяжитесь с администратором сайта' ? 'Ошибка' : 'Недостаточно средств'
          this.alertInfo = {
            alert_title: title,
            alert_message: errorMessage,
            cancel_btn_text: "Ок",
          };
          this.alertDialog = true;
        }
      }
      this.is_loading = false
    },
  },
  beforeRouteLeave(to, from, next) {
    this.clearProduct();
    next();
  },
};
</script>

<style lang="scss" scoped>

</style>
