<template>
  <div
    data-app
    class="market-search-container"
    :class="{ 'pos-rel': inputFocused }"
  >
    <input
      v-model="searchQuery"
      class="market-search-input"
      placeholder="Поиск по магазину"
      @focus="openSearch"
      @keyup.enter="startSearch"
    />
    <div
      class="market-search-actions is-merch"
      :class="{ 'list-of-products': isListOfProducts }"
    >
      <button
        v-if="trimmedSearch"
        type="button"
        class="market-search-btn"
        @click="startSearch"
      >
        Поиск
      </button>
      <button
        v-if="inputFocused"
        type="button"
        class="market-cancel-search-btn"
        @click="cancelSearch"
      >
        <img src="@/assets/marketplace/cancel_icon.svg" alt="cancel" />
      </button>
    </div>
    <div v-if="inputFocused" class="search-result-container">
      <template v-if="searchResult && searchResult.length > 0">
        <router-link
          v-for="product in searchResult"
          :key="product.id"
          :to="{ name: 'MerchProductById', params: { productId: product.id } }"
        >
          <div class="search-product">{{ product.title }}</div>
          <hr class="market-divider" />
        </router-link>
      </template>
      <template v-if="searchResult && searchResult.length === 0">
        <div v-if="emptyResult" class="search-product">{{ emptyResult }}</div>
      </template>
    </div>
    <div v-if="isListOfProducts" class="market-sort-filter-container">
      <button type="button" style="height: 26px" @click="openSortingPanel">
        <img src="@/assets/marketplace/sort_icon.svg" alt="sort" />
      </button>
    </div>
    <div
      v-if="isListOfProducts"
      class="sorting-panel-bg"
      :class="{ active: isSortingOpen }"
      @click.self="closeSortingPanel"
    >
      <div class="sorting-panel" :class="{ active: isSortingOpen }">
        <div
          class="sorting-list"
          :class="{ 'with-btn': queryParams.has('order_by') }"
        >
          <div
            class="sorting-option"
            :class="{
              'selected-sorting-option':
                queryParams.get('order_by') === sortOption.code,
            }"
            v-for="sortOption in sortingOptions"
            :key="sortOption.code"
            @click="setFilterQuery('order_by', sortOption.code)"
          >
            {{ sortOption.title }}
            <img
              v-if="queryParams.get('order_by') === sortOption.code"
              src="@/assets/marketplace/check.svg"
              alt="check"
            />
          </div>
        </div>
        <button
          v-if="queryParams.has('order_by')"
          type="button"
          class="confirm-sorting-btn"
          @click="resetSorting"
        >
          <span class="confirm-sorting-text">Сбросить</span>
        </button>
      </div>
    </div>
    <LoadingDialog ref="loadingDialog" />
  </div>
</template>

<script>
import LoadingDialog from "@/components/marketplace/loading_dialog.vue";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    isListOfProducts: Boolean,
    categoryId: [Number, String],
  },
  data: () => ({
    searchQuery: "",
    isSearched: false,
    inputFocused: false,
    isSortingOpen: false,
    sortingOptions: [
      { title: "Сначала дешёвые", code: "price_inc" },
      { title: "Сначала дорогие", code: "price_dec" },
    ],
  }),
  components: {
    LoadingDialog,
  },
  computed: {
    ...mapState("merch", [
      "api_url",
      "searchResult",
      "queryParams",
    ]),
    trimmedSearch() {
      return this.searchQuery.trim();
    },
    emptyResult() {
      if (this.isSearched) return "По вашему запросу ничего не найдено";
      else return "";
    },
  },
  beforeDestroy() {
    this.cancelSearch();
  },
  methods: {
    ...mapActions("merch", [
      "searchForProduct",
      "clearSearchResult",
      "setQueryParams",
      "deleteQueryParam",
      "clearQueryParams",
      "getProductsByFilter",
      "clearProductsByFilter",
    ]),
    async startSearch() {
      if (this.trimmedSearch !== "") {
        await this.searchForProduct(this.trimmedSearch);
        this.isSearched = true;
      }
    },
    openSearch() {
      this.inputFocused = true;
      this.$emit("disableScroll");
    },
    cancelSearch() {
      this.inputFocused = false;
      this.isSearched = false;
      this.searchQuery = "";
      this.clearSearchResult();
      this.$emit("enableScroll");
    },
    openSortingPanel() {
      this.isSortingOpen = true;
      this.$emit("disableScroll");
    },
    closeSortingPanel() {
      this.isSortingOpen = false;
      this.$emit("enableScroll");
    },
    async resetSorting() {
      await this.deleteQueryParam('order_by');
      this.applyFiltering();
    },
    async setFilterQuery(name, value) {
      if (this.queryParams.get(name) === value) {
        this.deleteQueryParam(name);
      } else {
        this.setQueryParams({ name, value });
      }
      this.$forceUpdate();
      await this.applyFiltering();
    },
    async applyFiltering() {
      this.$refs.loadingDialog.startLoading();
      if (this.isSortingOpen) this.closeSortingPanel();
      this.$emit("activateSkeletonLoader");
      await this.clearProductsByFilter();
      await this.getProductsByFilter(this.categoryId);
      this.$emit("deactivateSkeletonLoader");
      this.$refs.loadingDialog.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.pos-rel {
  position: relative;
}
.header-right-icon {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #267CCC;
}
.confirm-filters-btn-container {
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow:
    0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.confirm-filters-btn {
  padding: 16px;
  height: 56px;
  width: 100%;
  border-radius: 16px;
  background: #ef7f1a;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
</style>
