<template>
  <div>
    <v-bottom-navigation
      fixed
      height="64"
      color="#EF7F1A"
      :value="currentRoute"
    >
      <v-btn
        value="Market"
        :to="{ name: 'Merch', params: { isFromFooter: true } }"
      >
        <span>Категории</span>
        <v-icon>mdi-view-list</v-icon>
      </v-btn>
      <v-btn
        value="Cart"
        :to="{ name: 'MerchCart', params: { isFromFooter: true } }"
      >
        <span>Корзина</span>
        <v-badge
          :content="cart_qty_total"
          :value="cart_qty_total > 0"
          color="#F5463B"
          overlap
        >
          <v-icon> mdi-cart </v-icon>
        </v-badge>
      </v-btn>
      <v-btn
        value="Orders"
        :to="{ name: 'MerchOrders', params: { isFromFooter: true } }"
      >
        <span>Заказы</span>
        <v-icon>mdi-clipboard-text-outline</v-icon>
      </v-btn>
<!--      <v-btn-->
<!--        value="Support"-->
<!--        :to="{ name: 'MerchSupport', params: { isFromFooter: true } }"-->
<!--      >-->
<!--        <span>Поддержка</span>-->
<!--        <v-icon>mdi-headset</v-icon>-->
<!--      </v-btn>-->
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    ...mapGetters("merch", ["cart_qty_total"]),
  },
};
</script>

<style lang="scss" scoped>
</style>
