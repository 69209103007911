<template>
  <div class="feedback-container" :class="{ active: isFeedbackOpen }">
    <header class="market-header-container">
      <div class="market-header-card">
        <div class="market-header-title">
          <span class="cart-header-title" style="margin: 0 auto">
            Отзыв
          </span>
        </div>
      </div>
    </header>
    <div class="send-feedback-margin">
      <div v-if="lineInfo" class="product-container">
        <div class="order-photo-container">
          <img
            v-if="lineInfo.product.image"
            :src="api_url + lineInfo.product.image"
            alt="product"
            style="max-width: 100%; max-height: 100%"
          />
          <img
            v-else
            src="@/assets/marketplace/no_image.svg"
            alt="no_image"
            style="max-width: 100%; max-height: 100%"
          />
        </div>
        <div class="product-text">
          <span class="product-vendor-code">
            АРТИКУЛ: {{ lineInfo.stockrecord.partner_sku }}
          </span>
          <span class="product-title">
            {{ lineInfo.product.title }}
          </span>
        </div>
      </div>
      <div class="rating-container">
        <span class="rating-text">Оцените товар</span>
        <v-rating
          v-model="rating"
          size="20"
          color="#EF7F1A"
          :ripple="false"
          dense
        ></v-rating>
      </div>
      <div class="feedback-text-container">
        <textarea
          v-model="feedbackText"
          placeholder="Ваш отзыв важен для коллег при выборе товара. Напишите развёрнутый текст..."
          class="feedback-text"
        ></textarea>
      </div>
      <div class="image-container">
        <span class="add-image-title">Добавьте до 5 фотографий</span>
        <input
          ref="imageInput"
          type="file"
          accept="image/*"
          multiple
          style="display: none"
          @change="handleImageInputChange"
        />
        <div class="d-flex">
          <div v-if="images && images.length" class="d-flex">
            <div
              v-for="(image, index) in images"
              :key="index"
              class="review-added-images-container"
            >
              <img
                :src="createObjUrl(image)"
                :alt="`img_${index}`"
                class="review-added-image"
              />
              <div
                class="remove-added-image-btn"
                @click="deleteImageByIndex(index)"
              >
                <span>✕</span>
              </div>
            </div>
          </div>
          <div
            v-if="images && images.length < 5"
            @click="$refs.imageInput.click()"
            class="add-image-container"
          >
            <img
              src="@/assets/marketplace/add_image.svg"
              alt="add_image"
              class="add-image"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="send-feedback-btn-container">
      <button
        type="button"
        class="send-feedback-btn"
        :disabled="!isFeedbackValid"
        @click="submitFeedback"
      >
        Отправить отзыв
      </button>
    </div>
    <LoadingDialog ref="loadingDialog" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoadingDialog from "@/components/marketplace/loading_dialog.vue";

export default {
  props: {
    lineInfo: Object,
    isFeedbackOpen: Boolean,
  },
  data: () => ({
    rating: undefined,
    feedbackText: undefined,
    images: [],
  }),
  components: {
    LoadingDialog,
  },
  computed: {
    ...mapState("merch", ["api_url"]),
    trimmedFeedbackText() {
      if (this.feedbackText) return this.feedbackText.trim();
      else return undefined;
    },
    isFeedbackValid() {
      return this.rating && this.trimmedFeedbackText?.length >= 10
    },
  },
  methods: {
    ...mapActions("merch", ["sendFeedback"]),
    handleImageInputChange(event) {
      if (this.images.length + event?.target?.files?.length > 5) {
        this.$store.dispatch('layout/alert', 'Загружать больше 5 фото нельзя!');
      }
      this.images = [
        ...this.images,
        ...event.target.files,
      ].slice(0,5);
    },
    createObjUrl(img) {
      return URL.createObjectURL(img);
    },
    deleteImageByIndex(index) {
      this.$delete(this.images, index);
    },
    async submitFeedback() {
      if (this.isFeedbackValid) {
        try {
          this.$emit("startLoading");
          if (this.images?.length) {
            let feedbackData = new FormData;
            feedbackData.append('body', this.trimmedFeedbackText);
            feedbackData.append('score', this.rating);
            for (let i = 0; i < this.images.length; i++) {
              feedbackData.append('uploaded_images', this.images[i]);
            }
            for (const value of feedbackData.values()) {
              console.log(value);
            }
            await this.sendFeedback({
              productId: this.lineInfo.product.id,
              feedbackData,
            });
          } else {
            let feedbackData = {
              body: this.trimmedFeedbackText,
              score: this.rating,
            }
            await this.sendFeedback({
              productId: this.lineInfo.product.id,
              feedbackData,
            });
          }
          this.emitCloseFeedback();
          this.$emit("feedbackSuccess");
          this.$emit("stopLoading");
        } catch (err) {
          this.$emit("stopLoading");
          let errMessage = err.response ? err.response?.data.message : err;
          this.$emit("feedbackError", errMessage);
        }
      }
    },
    emitCloseFeedback() {
      this.$emit("closeFeedback");
      this.clearFeedback();
    },
    clearFeedback() {
      setTimeout(() => {
        this.rating = undefined;
        this.feedbackText = undefined;
        this.images = [];
      }, 300);
    },
  },
}
</script>

<style lang="scss" scoped>
.feedback-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  overflow-y: scroll;
  background-color: #F9F9F9;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transition: transform 0.3s ease;
}
.feedback-container.active {
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.send-feedback-margin {
  margin-bottom: 88px;
}
.product-container, .rating-container,
.feedback-text-container, .image-container {
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 16px;
  background-color: #FFFFFF;
}
.product-container {
  display: flex;
  align-items: center;
}
.product-text {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.product-vendor-code {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #8E8E93;
}
.product-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.rating-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rating-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.feedback-text {
  padding-right: 8px;
  height: 120px;
  width: 100%;
  resize: none;
  outline: none;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.image-container {
  display: flex;
  flex-direction: column;
}
.add-image-title {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
.review-added-images-container {
  margin-right: 8px;
  position: relative;
  display: flex;
}
.review-added-image {
  width: 56px;
  height: 56px;
  border-radius: 8px;
  object-fit: cover;
}
.remove-added-image-btn {
  position: absolute;
  top: -2px;
  right: -2px;
  height: 21px;
  width: 21px;
  background-color: #B4B4B4;
  border: 3px solid #FFFFFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-added-image-btn > span {
  padding-top: 1px;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
}
.add-image-container {
  margin-right: 8px;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #DDE1E5;
}
.add-image {
  margin-left: 14px;
}
.send-feedback-btn-container {
  padding: 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
}
.send-feedback-btn {
  padding: 16px;
  width: 100%;
  border-radius: 16px;
  background: #ef7f1a;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}
.send-feedback-btn:disabled {
  color: #8e8e93;
  background: rgba(239, 127, 26, 0.08);
}
</style>
